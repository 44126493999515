<template>
  <div>
    <div class="py-16 text-center" v-if="isLoading">
      <v-progress-circular size="42" indeterminate />
    </div>
    <div class="py-16 text-center" v-else-if="!vendors.length">
      Здесь ничего нет
    </div>
    <v-simple-table class="brands-list" v-else>
      <template v-slot:default>
        <thead>
          <tr class="brands-list__header">
            <th class="text-left" width="60px">
              <v-checkbox v-model="toggleAllItems" color="black" />
            </th>
            <th class="text-left" v-text="'№'" width="60px" />
            <th class="text-left" v-text="'Корректное написание'" width="15%" />
            <TableHeaderCell class="text-left" title="Проверка написания (количество товаров)" field="name" width="20%" />
            <th class="text-left" v-text="'Варианты написания'" />
            <th class="text-left" v-text="'Поставщик'" width="8%" />
            <th />
          </tr>
        </thead>
        <tbody>
          <BrandsListRow v-for="(item, index) in vendors" :key="item.id" :item="item" :row-number="indexForHuman(index)" />
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState, mapActions, mapMutations } from 'vuex';
import BrandsListRow from '@/components/Brands/BrandsListRow';
import TableHeaderCell from '@/components/common/TableHeaderCell';

export default {
  components: {
    BrandsListRow,
    TableHeaderCell,
  },
  props: {
    page: { type: Number, required: true },
    itemsPerPage: { type: Number, required: true },
  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      selectedRows: {
      },
    };
  },
  computed: {
    ...mapState('vendors', ['vendors', 'selectedVendorsItems']),
    queryForItems() {
      return { ...this.$route.query, page_size: this.itemsPerPage };
    },
    toggleAllItems: {
      get() {
        return this.vendors.every((vendor) => this.selectedVendorsItems.includes(vendor.id));
      },
      set(val) {
        const itemsIds = this.vendors.map((vendor) => vendor.id);
        let nextItems = null;

        if (!val) {
          nextItems = this.selectedVendorsItems.filter((selectedItem) => !itemsIds.includes(selectedItem));
        } else {
          nextItems = new Set([
            ...this.selectedVendorsItems,
            ...itemsIds,
          ]);
        }

        this.SET_SELECTED_VENDORS_ITEMS([...nextItems]);
      },
    },
  },
  watch: {
    queryForItems: {
      deep: true,
      handler: debounce(function () {
        this.fetchItems();
      }, 400),
    },
  },
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('vendors', ['FETCH_VENDORS']),
    ...mapMutations('vendors', ['SET_SELECTED_VENDORS_ITEMS']),
    async fetchItems() {
      this.isLoading = true;

      try {
        await this.FETCH_VENDORS(this.queryForItems);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = e.isAxiosCancelError;
        if (!e.isAxiosCancelError) this.$notify.showErrorMessage(e.response);
        if (!e.isAxiosError && !e.isAxiosCancelError) throw e;
      }
    },
    indexForHuman(index) {
      return (this.page - 1) * this.itemsPerPage + index + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.brands-list {
  &__header {
    color: hsla(0, 0%, 0%, 1);
    font-size: 12px;
    line-height: 16px;
    background: hsla(0, 0%, 98%, 1);

    th {
      padding: 0 6px !important; // trying to overwrite vuetify table styling
    }
  }
}
</style>
