<template>
  <div class="d-flex">
    <QueryFilterObserver v-bind="filters.withNames" />
    <QueryFilterObserver v-bind="filters.withProductsInListings" />
    <QueryFilterObserver v-bind="filters.brand" />
    <QueryFilterObserver v-bind="filters.search" />
  </div>
</template>

<script>
import QueryFilterObserver from '@/components/QueryFilterObserver';

export default {
  components: {
    QueryFilterObserver,
  },
  computed: {
    filters() {
      return {
        brand: {
          component: 'v-select',
          name: 'binding_with_canonical_status',
          attrs: {
            label: 'Все бренды',
            items: [{ text: 'Проверенные', value: 'bound' }, { text: 'Непроверенные', value: 'unbound' }],
            clearable: true,
            class: 'input-field input-field__select mr-4',
            hideDetails: true,
          },
        },
        search: {
          component: 'v-text-field',
          name: 'autocomplete',
          attrs: {
            class: 'input-field input-field__search',
            label: 'Найти бренд',
            clearable: true,
            hideDetails: true,
            outlined: true,
            dense: true,
            appendIcon: 'mdi-magnify',
          },
        },
        withNames: {
          component: 'v-select',
          name: 'with_name_usage',
          attrs: {
            label: 'Название',
            items: [{ text: 'Используется', value: true }, { text: 'Не используется', value: false }],
            clearable: true,
            class: 'input-field input-field__select mr-4',
            hideDetails: true,
          },
        },
        withProductsInListings: {
          component: 'v-select',
          name: 'with_products_in_listings',
          attrs: {
            label: 'Товары в листингах',
            items: [{ text: 'Есть в листингах', value: true }, { text: 'Нет в листингах', value: false }],
            clearable: true,
            class: 'input-field input-field__select mr-4',
            hideDetails: true,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.input-field {
  font-size: 12px;

  &__select {
    max-width: 176px;
  }

  &__search {
    max-width: 240px;
  }
}

::v-deep .input-field .v-label {
  font-size: 12px;
}
</style>
