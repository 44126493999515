<template>
  <v-dialog v-model="show" width="440px" scrollable>
    <div class="brands-modal">
      <div class="brands-modal-header">
        <div>
          <div class="brands-modal-header__title">Редактирование написания бренда</div>
        </div>
        <button @click="$emit('close')">
          <v-icon class="ml-auto">mdi-close</v-icon>
        </button>
      </div>
      <div class="brands-modal-body">
        <div class="brands-modal-header__subtitle">
          Сохранённое написание:
        </div>
        <div>
          <div class="brands-modal-brand" v-html="savedName" />
        </div>
        <div class="brands-modal-header__subtitle">
          Другие варианты написания:
        </div>
        <div class="d-flex">
          <template v-for="synonym in item.synonyms">
            <div class="brands-modal-brand" :key="synonym" v-html="highlighted(synonym)" />
          </template>
        </div>
        <div class="brands-modal-header__subtitle">
          Новое написание (другие варианты написания будут связаны с новым написанием):
        </div>
        <div>
          <v-text-field class="field" v-model="canonicalBrandName" hide-details outlined dense @keyup.enter="submit" />
        </div>
      </div>
      <div class="brands-modal-footer">
        <AppButton theme="primary" text="Запомнить новое написание" @click.native="submit" />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import AppButton from '@/components/AppButton';
import hitHighlighter from '@/helpers/hitHighlighter';

export default {
  components: {
    AppButton,
  },
  props: {
    show: { type: Boolean, default: false },
    item: { type: Object, required: true },
  },
  data() {
    return {
      canonicalBrandName: null,
    };
  },
  computed: {
    savedName() {
      return hitHighlighter(`${this.item.vendor?.name ?? ''} (${this.item.name_usage_count})`, this.item.vendor?.name ?? '');
    },
  },
  methods: {
    async submit() {
      if (await this.$root.$confirm('Сохранение', 'Вы пытаетесь переименовать бренд. Уверены?')) {
        this.action();
      }
    },
    async action() {
      try {
        await this.$store.dispatch('vendors/RENAME_VENDOR', { vendorId: this.item.id, newName: this.canonicalBrandName });
        this.$emit('close');
        this.$notify.showMessage({ message: 'Сохранено', color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      }
    },
    highlighted(val) {
      return hitHighlighter(val, val);
    },
  },

};
</script>

<style lang="scss" scoped>
.brands-modal {
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);

  &-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-weight: 500;
    }

    &__subtitle {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: hsla(0, 0%, 0%, 0.48);
      padding-bottom: 8px;
      &:not(:first-child) {
        padding-top: 24px;
      }
    }
  }

  &-body {
    padding: 16px;
    border-top: 1px solid hsl(0, 0%, 0%, 8%);
    border-bottom: 1px solid hsl(0, 0%, 0%, 8%);
    overflow-x: auto;
    overflow-y: auto;
  }

  &-footer {
    display: flex;
    justify-content: center;
    padding:  12px;

    &__button {
      font-size: 12px;

      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  &-brand {
    font-size: 12px;
    padding-right: 16px;
  }
}

.field {
  font-size: 12px;
}

::v-deep .v-label {
  font-size: 12px;
}
</style>
