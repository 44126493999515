<template>
  <v-dialog v-model="isVisible" width="440px" scrollable>
    <div class="brands-modal">
      <div class="brands-modal-header">
        <div>
          <div class="brands-modal-header__title">Переименивание нескольких брендов</div>
          <div class="brands-modal-header__subtitle">Какое название указать для выбранных брендов?</div>
        </div>
        <button @click="close">
          <v-icon class="ml-auto">mdi-close</v-icon>
        </button>
      </div>
      <div class="brands-modal-body">
        <div class="brands-modal-body__subtitle">
          Корректное написание:
        </div>
        <div>
          <BrandsCanonicalSearch :value="canonicalBrandName" @input="brandInput" label="Напишите название бренда" over @new-value="(val) => newBrand = val" />
        </div>
        <div class="brands-modal-body__subtitle">
          Выбранные бренды:
        </div>
        <div class="d-flex flex-wrap">
          <template v-for="vendor in selectedVendorsObjects">
            <div class="brands-modal-brand" :key="vendor.id" v-html="highlighted(vendor.name, vendor.name_usage_count)" />
          </template>
        </div>
      </div>
      <div class="brands-modal-footer">
        <AppButton theme="primary" text="Сохранить написание" @click.native="submit" />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import hitHighlighter from '@/helpers/hitHighlighter';

import AppButton from '@/components/AppButton';
import BrandsCanonicalSearch from '@/components/Brands/BrandsCanonicalSearch';

export default {
  components: {
    AppButton,
    BrandsCanonicalSearch,
  },
  data() {
    return {
      modalName: 'BrandMassRenameModal',
      canonicalBrandName: null,
      newBrand: false,
    };
  },
  computed: {
    ...mapState('modal', ['component']),
    ...mapState('vendors', ['selectedVendorsItems']),
    ...mapGetters('vendors', ['selectedVendorsObjects']),
    isVisible: {
      get() {
        return this.modalName === this.component;
      },
      set() {
        this.close();
      },
    },
  },
  methods: {
    ...mapMutations('vendors', ['SET_SELECTED_VENDORS_ITEMS']),
    ...mapMutations('modal', ['HIDE_MODAL']),
    beforeClose() {
      this.canonicalBrandName = null;
      this.newBrand = false;
    },
    brandInput({ val, isNew }) {
      this.canonicalBrandName = val;
      this.newBrand = isNew;
    },
    highlighted(name, count) {
      return hitHighlighter(`${name} (${count})`, name);
    },
    async submit() {
      if (this.newBrand && await this.$root.$confirm('Сохранение', 'Вы пытаетесь добавить новое каноничное название. Уверены?')) {
        this.action();
      } else {
        this.action();
      }
    },
    async action() {
      try {
        await this.$store.dispatch('vendors/BIND_VENDORS', {
          canonical_vendor_name: this.canonicalBrandName,
          inventory_vendors: this.selectedVendorsItems,
        });
        this.SET_SELECTED_VENDORS_ITEMS([]);
        this.close();
        this.$notify.showMessage({ message: 'Сохранено', color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      }
    },
    close() {
      this.HIDE_MODAL();
    },
  },
};
</script>

<style lang="scss" scoped>
.brands-modal {
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);

  &-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-weight: 500;
    }

    &__subtitle {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: hsla(0, 0%, 0%, 0.48);
      padding-bottom: 8px;
    }
  }

   &-body {
    padding: 16px;
    border-top: 1px solid hsl(0, 0%, 0%, 8%);
    border-bottom: 1px solid hsl(0, 0%, 0%, 8%);
    overflow-x: auto;
    overflow-y: auto;

    &__subtitle {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: hsla(0, 0%, 0%, 0.48);
      padding-bottom: 8px;
      &:not(:first-child) {
        padding-top: 24px;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    padding: 12px;

    &__button {
      font-size: 12px;

      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  &-brand {
    font-size: 12px;
    margin-right: 10px;
    margin-bottom: 8px;
  }
}
</style>
