<template>
  <FloatingActionsBar :selected-items="selectedVendorsItems" @close="clearAll">
    <AppButton text="Указать корректное написение выбранным товарам" @click.native="processOperations" />
  </FloatingActionsBar>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import FloatingActionsBar from '@/components/FloatingActionsBar';
import AppButton from '@/components/AppButton';

export default {
  components: {
    FloatingActionsBar,
    AppButton,
  },
  computed: {
    ...mapState('vendors', ['selectedVendorsItems']),
  },
  methods: {
    ...mapMutations('vendors', ['SET_SELECTED_VENDORS_ITEMS']),
    ...mapMutations('modal', ['SHOW_MODAL']),
    clearAll() {
      this.SET_SELECTED_VENDORS_ITEMS([]);
    },
    processOperations() {
      this.SHOW_MODAL({
        component: 'BrandMassRenameModal',
      });
    },
  },
};
</script>
