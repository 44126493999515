<template>
  <div>
    <v-combobox class="input-field" v-model="name" :loading="isLoading" :items="vendorsForSelect" :search-input.sync="search" :label="label" :allow-overflow="false" :background-color="focused ? 'hsla(0, 0%, 100%, 1)' : 'hsla(0, 0%, 100%, 0)'" outlined hide-details hide-no-data dense @focus.stop="focused = true" @blur="onBlur" v-if="over || focused" append-icon="">
      <template v-slot:append-outer v-if="showCorrect && value">
        <v-btn @click="show" icon small>
          <v-icon>
            mdi-dots-horizontal
          </v-icon>
        </v-btn>
      </template>
    </v-combobox>
    <div class="text--secondary brand-text d-flex align-center" v-text="value" v-else />
    <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute>
      <div class="list-text" @click="showEdit">Редактировать написание бренда</div>
    </v-menu>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    value: { type: String, default: '' },
    over: { type: Boolean, default: false },
    showCorrect: { type: Boolean, default: false },
    label: { type: String, default: '' },
  },
  data() {
    return {
      isLoading: false,
      search: null,
      focused: false,
      showMenu: false,
      x: 0,
      y: 0,
    };
  },
  computed: {
    ...mapGetters('canonical_vendors', ['vendorsForSelect']),
    name: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', ({ val, isNew: this.vendorsForSelect.indexOf(val) === -1 }));
      },
    },
  },
  watch: {
    search: {
      handler: debounce(async function (val) {
        this.querySelections({
          autocomplete: val,
        });
      }, 500),
    },
  },
  mounted() {
    if (this.select) {
      this.querySelections({
        autocomplete: this.name,
      });
    }
  },
  methods: {
    ...mapActions('canonical_vendors', ['FETCH_VENDORS']),
    async querySelections(params) {
      this.isLoading = true;

      try {
        await this.FETCH_VENDORS(params);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = e.isAxiosCancelError;
        if (!e.isAxiosCancelError) this.$notify.showErrorMessage(e.response);
        if (!e.isAxiosError && !e.isAxiosCancelError) throw e;
      }
    },
    onBlur() {
      this.focused = false;
      this.$emit('input', ({ val: this.search, isNew: this.vendorsForSelect.indexOf(this.search) === -1 }));
    },
    show(e) {
      this.showMenu = false;
      this.x = e.clientX - 205;
      this.y = e.clientY + 20;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    showEdit() {
      this.$emit('edit');
      this.showMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-field {
  font-size: 12px;
  max-width: 220px;
}

::v-deep .v-select__slot .v-label {
  font-size: 12px;
}

.brand-text {
  font-size: 12px;
  width: 220px;
  height: 40px;
  text-align: start;
  vertical-align: center;
  padding-left: 12px;
  border: 1px solid hsla(0, 0%, 0%, 0.08);
  border-radius: 6px;
}

.list-text {
  min-width: 225px;
  font-size: 12px;
  color: hsla(227, 100%, 55%, 1);
  background-color: white;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
}
</style>
