<template>
  <tr class="brand-item-row" :class="{ 'brand-item-row--active': itemIsActive }" @click="toggleItem" @mouseover="showAction = true" @mouseleave="showAction = false">
    <td>
      <v-checkbox :input-value="itemIsActive" color="black" @change="toggleItem" />
    </td>
    <td class="text-left text-caption" v-text="rowNumber" />
    <td class="py-2">
      <BrandsCanonicalSearch :value="canonicalBrandName" @input="brandInput" :over="showAction" show-correct @edit="showEdit = true" />
    </td>
    <td class="text-left text-caption" :class="{ 'brand-item-row__strike': item.vendor }" v-html="`${itemName} (${item.name_usage_count})`" />
    <td class="text-left text-caption brand-item-row__strike" v-html="variants" />
    <td class="text-left text-caption brand-item-row__lighted" v-text="item.owner.name" />
    <td class="text-right brand-item-row__action">
      <AppButton theme="primary" text="Сохранить написание" @click.native="submit" v-show="showAction" />

      <v-btn depressed small text height="32" width="64px" v-if="item.vendor && !showAction">
        <v-icon> mdi-check </v-icon>
      </v-btn>
    </td>
    <BrandRenameModal :item="item" :show="showEdit" @close="showEdit = false" />
  </tr>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import AppButton from '@/components/AppButton';
import BrandsCanonicalSearch from '@/components/Brands/BrandsCanonicalSearch';
import BrandRenameModal from '@/components/Brands/BrandRenameModal';

export default {
  components: {
    BrandsCanonicalSearch,
    AppButton,
    BrandRenameModal,
  },
  props: {
    rowNumber: { type: Number, default: 0 },
    item: { type: Object, required: true },
  },
  data() {
    return {
      canonicalBrandName: null,
      showAction: false,
      newBrand: false,
      showEdit: false,
    };
  },
  computed: {
    ...mapState('vendors', ['selectedVendorsItems']),
    variants() {
      return this.item.synonyms.map((val) => `<s>${val}</s>`).join('    ') || '—';
    },
    itemName() {
      return this.item.vendor ? `<s>${this.item.name}</s>` : this.item.name;
    },
    itemIsActive() {
      return this.selectedVendorsItems.includes(this.item.id);
    },
  },
  watch: {
    'item.vendor': {
      deep: true,
      immediate: true,
      handler() {
        this.canonicalBrandName = this.item.vendor?.name;
      },
    },
  },
  methods: {
    ...mapMutations('vendors', ['TOGGLE_SELECTED_VENDORS_ITEMS']),
    brandInput({ val, isNew }) {
      this.canonicalBrandName = val;
      this.newBrand = isNew;
    },
    async submit() {
      if (!this.newBrand) return this.action();

      if (await this.$root.$confirm('Сохранение', 'Вы пытаетесь добавить новое каноничное название. Уверены?')) {
        this.action();
      }
    },
    async action() {
      try {
        await this.$store.dispatch('vendors/BIND_VENDORS', {
          canonical_vendor_name: this.canonicalBrandName,
          inventory_vendors: [this.item.id],
        });
        this.$notify.showMessage({ message: 'Сохранено', color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      }
    },
    toggleItem(event) {
      if (['A', 'INPUT', 'BUTTON', 'I'].includes(event.target?.tagName) || event.target?.className.includes('input')) return;

      this.TOGGLE_SELECTED_VENDORS_ITEMS(this.item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-item-row {
  background-color: hsla(0, 0%, 98%, 1);
  transition: background-color 0.1s;
  cursor: pointer;

  &--active {
    background-color: hsl(227, 100%, 55%, 8%);
  }

  td {
    padding: 0 6px !important; // trying to overwrite vuetify table styling
  }

  &__strike {
    white-space: pre;
    color: hsla(0, 0%, 0%, 0.4);
  }

  &__lighted {
    color: hsla(0, 0%, 0%, 0.4);
  }

  &__action {
    width: 250px;
  }
}
</style>
