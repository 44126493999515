<template>
  <v-container fluid>
    <v-col cols="12 sticky">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>
          <div class="d-flex align-baseline">
            <div v-text="'Бренды'" />
            <div class="caption text--secondary ml-4" v-text="vendorsCount" v-if="vendorsCount" />
          </div>
        </v-toolbar-title>

        <v-spacer />

        <BrandsFilters />
      </v-toolbar>
    </v-col>

    <v-col cols="12 pa-0">
      <BrandsList :page="page" :items-per-page="itemsPerPage" />
    </v-col>

    <v-row class="my-2" justify="center" v-if="pageCount">
      <v-col offset="4" cols="3">
        <v-pagination v-model="page" :length="pageCount" />
      </v-col>
      <v-col offset="4" cols="1">
        <v-select v-model="itemsPerPage" label="Количество строк" :items="itemsPerPageSelect" :menu-props="{ top: true, offsetY: true }" outlined dense />
      </v-col>
    </v-row>

    <BrandsSelectedItemsActions v-if="selectedVendorsItems.length" />
    <BrandMassRenameModal />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

import PaginationMixin from '@/mixins/PaginationMixin';

import BrandsFilters from '@/components/Brands/BrandsFilters';
import BrandsList from '@/components/Brands/BrandsList';
import BrandMassRenameModal from '@/components/Brands/BrandMassRenameModal';
import BrandsSelectedItemsActions from '@/components/Brands/BrandsSelectedItemsActions';

export default {
  components: {
    BrandsFilters,
    BrandsList,
    BrandMassRenameModal,
    BrandsSelectedItemsActions,
  },
  mixins: [PaginationMixin],
  data() {
    return {
      itemsPerPage: 24,
      itemsPerPageSelect: [12, 24, 48, 60],
    };
  },
  metaInfo() {
    return {
      title: 'Бренды — Инвентарь',
    };
  },
  computed: {
    ...mapState('vendors', ['vendors', 'vendorsCount', 'selectedVendorsItems']),
    pageCount() {
      return Math.ceil(this.vendorsCount / this.itemsPerPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  padding-bottom: 16px;
  position: sticky;
  top: 0;
  background-color: hsl(0, 0%, 100%);
  border-bottom: 1px solid hsla(0, 0%, 92%, 1);
  z-index: 5;
}

</style>
